<template>
  <div class="root-container container" id="hallOfFameContainer">
    <div class="error" v-if="error && error.summary">
      <Alert :alert="error"></Alert>
    </div>

    <div class="row section-container">
      <div class="col">
        <h1>Text correction hall of fame</h1>

        <div class="content">
          <div class="row">
            <div class="col col-sm-12 col-md-9">
              <p
                >Automatically extracting text from scans of old newspapers, gazettes, magazines,
                newsletters and books is extremely challenging. Although the best available Optical
                Character Recognition (OCR) software has been used, the condition of the images it
                has to process combined with the frequently small fonts used means that many errors
                of interpretation are made.
              </p>

              <p
                >Thankfully, many people have stepped in to correct the text and in so doing have
                made a wonderful contribution to this resource, and helped to improve search
                results. The following table lists by month the people who've corrected the most
                lines of text.
              </p>

              <p
                ><strong>Total number of lines corrected for</strong> {{ timePeriodDisplay }} :
                <strong id="totalCorrections">{{ totalCorrections | formatNumber }}</strong>
              </p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <h2>Leaderboard</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hallOfFame">
      <div class="row">
        <div class="col col-sm-8 col-lg-9">
          <div class="form-inline ml-1">
            <div class="form-row">
              <div class="form-group">
                <label class="sr-only" for="correctionPeriod">
                  View leaderboard for time period
                </label>
                <b-form-select
                  id="correctionPeriod"
                  v-model="selectedPeriod"
                  class="form-control mr-2"
                  :disabled="isViewButtonDisabled">
                  <option v-for="(period, index) in periods" :key="index" :value="period.value">
                    {{ period.label }}
                  </option>
                </b-form-select>
              </div>
              <div class="form-group">
                <label class="sr-only" for="filter">and article types</label>
                <b-form-select
                  id="filter"
                  v-model="selectedFilter"
                  class="form-control mr-2"
                  :disabled="isViewButtonDisabled">
                  <option
                    v-for="(label, value, index) in filterOptions"
                    :key="`${index} ${value}`"
                    :value="value">
                    {{ label }}
                  </option>
                </b-form-select>
              </div>
              <div class="form-group">
                <b-button
                  variant="secondary"
                  size="sm"
                  :disabled="isViewButtonDisabled"
                  class="view-btn mb-0"
                  @click="updateStatsView">
                  View
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-sm-4 col-lg-3 text-right">
          <p v-if="authenticated">
            <strong>My rank:</strong>
            <span :class="authenticatedUserIsRanked ? 'font-weight-bold' : ''">
              {{ authenticatedUserRanking }}
            </span>
          </p>
          <p>
            Total number of correctors:
            <span class="numOfCorrectors">{{ totalCorrectors | formatNumber }}</span>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col col-12">
          <Stubbable :stubbed="loadingStats">
            <template v-slot:content>
              <table class="table table-responsive-xs mt-2 table-ranking">
                <thead>
                  <tr>
                    <th scope="col">Rank</th>
                    <th scope="col">Username</th>
                    <th scope="col" class="lines-corrected">Lines corrected</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(correction, index) in corrections" :key="index">
                    <template v-if="isEllipsisRow(index)">
                      <td>&hellip;</td>
                      <td>&hellip;</td>
                      <td class="lines-corrected">&hellip;</td>
                    </template>
                    <template v-else>
                      <template v-if="correction.listRanking > 0">
                        <td :class="rankingRowClass(correction.listRanking)">
                          {{ correction.listRanking | formatNumber }}
                        </td>
                        <td :class="rankingRowClass(correction.listRanking)">
                          <template v-if="correction.userName">
                            <router-link
                              :to="{
                                name: 'PublicProfile',
                                params: { username: correction.userName },
                              }">
                              {{ correction.userDisplayName }}
                            </router-link>
                          </template>
                          <template v-else>{{ correction.userDisplayName }}</template>
                        </td>
                        <td
                          class="lines-corrected"
                          :class="rankingRowClass(correction.listRanking)">
                          {{ correction.linesCorrected | formatNumber }}
                        </td>
                      </template>
                      <template v-else>
                        <td :class="rankingRowClass(correction.listRanking)"> &hellip; </td>
                        <td :class="rankingRowClass(correction.listRanking)"> &hellip; </td>
                        <td
                          class="lines-corrected"
                          :class="rankingRowClass(correction.listRanking)">
                          &hellip;
                        </td>
                      </template>
                    </template>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-slot:stub>
              <table class="table table-responsive-xs mt-2 table-ranking">
                <thead>
                  <tr>
                    <th scope="col">Rank</th>
                    <th scope="col">Username</th>
                    <th scope="col" class="lines-corrected">Lines corrected</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="3">
                      <div class="d-flex justify-content-center">
                        <div class="loading-spinner spinner-md"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </Stubbable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import colours from '@/mixins/colours'
import utils from '@/mixins/utils'
import mappings from '@/mixins/mappings'
import cultural from '@/mixins/cultural'
import currentRoute from '@/mixins/currentRoute'
import auth from '@/mixins/auth'
import service from '@/service/service'
import Alert from '@/components/Alert.vue'
import Stubbable from '@/components/Stubbable.vue'

const FILTER_OPTIONS = {
  all: 'All articles',
  newspaper: 'Newspaper articles',
  gazette: 'Gazette articles',
  book: 'Books',
  journal: 'Journals',
  // TODO: uncomment 'manuscripts' option when they are available
  // manuscript: 'Manuscripts',
}

export default {
  name: 'HallOfFameView',
  mixins: [colours, utils, mappings, auth, cultural, currentRoute],
  components: {
    Alert,
    Stubbable,
  },
  metaInfo() {
    return {
      title: 'Text correction hall of fame',
    }
  },
  created() {
    let self = this
    self.error = null

    self.selectedPeriod = _.isEmpty(self.$route.query.monthYearChoice)
      ? 'all'
      : self.$route.query.monthYearChoice

    self.selectedFilter = _.isEmpty(self.$route.query.filter)
      ? 'newspaper'
      : self.$route.query.filter

    self.updateBreadcrumbs()
    self.loadAllStatsPeriods()
    self.loadTotalCorrections()
    self.loadTotalCorrectors()
    self.loadCorrectionStats()
  },
  data() {
    return {
      allPeriods: [],
      periods: [],
      totalCorrections: 0,
      totalCorrectors: 0,
      corrections: [],
      currentPeriod: 'all',
      currentFilter: 'newspaper',
      selectedPeriod: 'all',
      selectedFilter: 'newspaper',
      currentRank: undefined,
      currentRankIndex: -1,
      spacerRows: [],
    }
  },
  computed: {
    timePeriodDisplay() {
      return this.currentPeriod === 'all' ? 'all time' : this.currentPeriod
    },
    filterOptions() {
      return FILTER_OPTIONS
    },
    loadingStats() {
      if (!_.isEmpty(this.error)) {
        return false
      } else {
        return _.isEmpty(this.periods) || _.isEmpty(this.corrections)
      }
    },
    isViewButtonDisabled() {
      return this.loadingStats
    },
    authenticatedUserIsRanked() {
      return this.corrections.length > 0 && this.currentRank && this.currentRank.linesCorrected > 0
    },
    authenticatedUserRanking() {
      if (this.authenticatedUserIsRanked) {
        return this.formatOrdinal(this.currentRank.listRanking)
      } else {
        return "I don't have any corrections for this period."
      }
    },
  },
  methods: {
    updateBreadcrumbs() {
      let crumbs = []
      this.$emit(
        'update-breadcrumbs',
        _.isEmpty(crumbs) ? [{ value: 'Text correction hall of fame' }] : crumbs,
        true
      )
    },
    loadAllStatsPeriods() {
      let self = this

      service.hallOfFame
        .getStatsPeriods('all')
        .then((data) => {
          self.allPeriods = data
          self.periods = data
        })
        .catch((error) => {
          self.handleError(error, 'corrections', undefined, 'retrieve the stats date periods')
        })
    },
    loadStatsPeriods() {
      let self = this

      service.hallOfFame
        .getStatsPeriods(self.selectedFilter)
        .then((data) => {
          self.periods = self.allPeriods.filter(
            (a) => data.some((d) => d.value === a.value) || a.value === self.selectedPeriod
          )
        })
        .catch((error) => {
          self.handleError(error, 'corrections', undefined, 'retrieve the stats date periods')
        })
    },
    loadTotalCorrections() {
      let self = this

      service.hallOfFame
        .getTotalCorrections(self.selectedPeriod, self.fullUsername, self.selectedFilter)
        .then((data) => {
          self.totalCorrections = data
        })
        .catch((error) => {
          self.handleError(error, 'corrections', undefined, 'retrieve the total corrections count')
        })
    },
    loadTotalCorrectors() {
      let self = this

      service.hallOfFame
        .getTotalCorrectors(self.selectedPeriod, self.selectedFilter)
        .then((data) => {
          self.totalCorrectors = data
        })
        .catch((error) => {
          self.handleError(error, 'corrections', undefined, 'retrieve total corrector count')
        })
    },
    loadCorrectionStats() {
      let self = this

      self.corrections = []
      self.spacerRows = []

      service.hallOfFame
        .get(self.selectedPeriod, self.fullUsername, self.selectedFilter)
        .then((data) => {
          self.corrections = data

          self.currentRank = self.corrections.find(
            (correction) => correction.userName === self.fullUsername
          )
          self.currentRankIndex = self.corrections.findIndex(
            (correction) => correction.userName === self.fullUsername
          )

          // insert spacer rows around user
          if (self.authenticated && self.currentRankIndex > -1) {
            const spacerCorrection = {
              listRanking: '-1',
              userName: 'user:public:spacer',
              userDisplayName: '...',
              linesCorrected: '-1',
            }
            let spacerIdxBefore = self.currentRankIndex - 3
            let spacerIdxAfter = self.currentRankIndex + 3
            if (spacerIdxBefore > 4) {
              self.spacerRows.push(spacerIdxBefore)
              self.corrections.splice(spacerIdxBefore, 0, spacerCorrection)
              spacerIdxAfter += 2
              self.currentRankIndex += 1
            }
            if (!self.isLastRow(spacerIdxAfter) && spacerIdxAfter < self.corrections.length) {
              self.spacerRows.push(spacerIdxAfter)
              self.corrections.splice(spacerIdxAfter, 0, spacerCorrection)
            }
          }
        })
        .catch((error) => {
          self.handleError(error, 'corrections', undefined, 'retrieve correction stats')
        })
    },
    updateStatsView() {
      let self = this
      self.error = null

      const updatedRoute = {
        name: 'HallOfFame',
        params: {},
        query: {
          monthYearChoice: self.selectedPeriod,
          filter: self.selectedFilter,
        },
      }

      self.$router.push(updatedRoute).catch(() => {
        // noop. Let the URL update, but catch the duplicate navigation error.
      })
    },
    rankingRowClass(rank) {
      let self = this

      if (self.currentRank) {
        return rank === self.currentRank.listRanking ? 'font-weight-bold' : ''
      }

      return ''
    },
    isEllipsisRow(index) {
      let self = this
      let ellipsisRow = false

      if (self.isLastRow(index) && index !== self.currentRankIndex) {
        ellipsisRow = true
      } else if (self.authenticated) {
        ellipsisRow = self.spacerRows.find((row) => row === index)
      }

      if (index < 5) {
        ellipsisRow = false
      }

      return ellipsisRow
    },
    isLastRow(index) {
      let self = this

      return index === self.corrections.length - 1
    },
  },
  watch: {
    '$route.query'() {
      let self = this

      self.loadStatsPeriods()
      self.loadTotalCorrections()
      self.loadTotalCorrectors()
      self.loadCorrectionStats()

      self.currentPeriod = self.periods.find((period) => period.value === self.selectedPeriod).label
      self.currentFilter = self.selectedFilter
    },
  },
}
</script>

<style scoped lang="scss">
.root-container {
  margin-bottom: 3rem;

  .error {
    margin-top: 2rem;
  }

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  h1,
  h2 {
    font-family: 'Source Serif Pro', serif;
    font-weight: 700;
  }

  @supports (font-variation-settings: normal) {
    h1,
    h2 {
      font-family: 'Source Serif Variable', serif;
    }
  }

  h1.bevel {
    font-family: inherit;
    font-size: 1.375rem;
  }

  .hallOfFame {
    .date-options,
    .filter-options {
      margin-right: 8px;
    }

    .view-btn {
      width: 200px;
      font-weight: 600;
      padding-top: 6px;
      padding-bottom: 6px;
      transition: all ease-in-out 0.3s;
      &:focus,
      &:active {
        background-color: colour(white);
        color: colour(purple);
      }
      &.disabled {
        opacity: 1;
        background-color: rgba(45, 0, 75, 0.65);
        &:hover,
        &:focus {
          border-color: colour(purple);
          background-color: rgba(45, 0, 75, 0.65);
          color: colour(white);
        }
      }
    }
  }

  .stub {
    .date-options {
      width: 161px;
      height: 38px;
      margin-right: 8px;
    }

    .filter-options {
      width: 251px;
      height: 38px;
      margin-right: 8px;
    }

    .view-btn {
      width: 200px;
      height: 38px;
    }

    .line {
      width: 300px;
    }

    .corrections {
      height: 600px;
    }
  }

  .numOfCorrectors {
    font-weight: bold;
  }
}

.table-ranking {
  .lines-corrected {
    text-align: right;
  }
}

// Global containers
.content-container {
  margin-right: 8%;

  @media (max-width: 575px) {
    margin-right: 0px;
  }
}

.section-container,
.find-more-container,
.resources-container {
  margin-top: 4em;
}
</style>
