<template>
  <b-container>
    <b-row v-if="showCollections" ref="collections">
      <b-col class="collections-container">
        <CollectionsBar
          :collections="collections"
          :selectedCollectionId="selectedCollectionId"
          :advanced="advanced" />
      </b-col>
    </b-row>

    <b-row ref="search">
      <b-col class="search-container">
        <SearchBar
          :key="currentRoute.name"
          :collections="collections"
          :selectedCollectionId="selectedCollectionId"
          :advanced="advanced"
          :canAdvanced="canAdvanced"
          @collection-changed="selectedCollectionUpdated"
          @search="search"
          @search-type-changed="searchTypeChanged"
          @field-config-updated="fieldConfigUpdated" />
      </b-col>
    </b-row>

    <b-row ref="terms">
      <b-col class="terms-container">
        <span class="limit" v-for="(limit, index) in displayLimits" :key="index">
          <span class="pill-container" v-for="(value, idx) in limit.value" :key="idx">
            <b-badge class="value" pill variant="primary">
              {{ limit.displayName || limit.limit }}: {{ value.display }}
              <router-link :to="generateLimitLink(limit.limit, value.original)"
                ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }"
              /></router-link>
            </b-badge>
          </span>
        </span>

        <span class="facet" v-for="(facet, index) in facetsWithSelectedTerms" :key="index">
          <span class="pill-container" v-for="term in selectedFacetTerms(facet)" :key="term.term">
            <b-badge class="term" pill variant="primary"
              >{{ facet.displayName }}: {{ term.displayTerm }}
              <router-link :to="generateFacetLink(facet.code, term.term)"
                ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }"
              /></router-link>
            </b-badge>
          </span>
        </span>

        <router-link v-if="displayClearAll" :to="generateCurrentPageLinkWithoutParams()"
          >Clear all</router-link
        >
      </b-col>
    </b-row>

    <b-row>
      <b-col class="results-container" role="list">
        <template v-if="hasQuery && (!isSummary || !globalSearchError)">
          <CollectionSearchResult
            v-for="collection in collectionsToSearch"
            :key="collection.id"
            @facets-updated="updateFacets"
            @search-result="updateLatestSearchResult"
            @global-search-error="globalSearchErrorOccurred"
            @order-changed="orderChanged"
            @explain-changed="explainChanged"
            :collection="collection"
            :isSummary="isSummary" />
        </template>
        <template v-if="globalSearchError && isSummary">
          <SearchError :search-error="globalSearchError"></SearchError>
        </template>
      </b-col>
      <b-col cols="3" class="trending-list-container" v-if="showTrendingLists">
        <TrendingLists />
      </b-col>
      <b-col cols="3" class="facets-container" v-if="showFacets && !showTrendingLists">
        <template v-if="hasQuery">
          <h2 class="bevel">Refine your results</h2>
          <template v-for="(facet, index) in facets">
            <Facet :key="facet ? facet.code : index" :facet="facet" />
          </template>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped lang="scss">
.search-container {
  margin-top: 2em;
}
.facets-container,
.trending-list-container {
  @media (max-width: 767.98px) {
    display: none;
    visibility: hidden;
  }
  .bevel:before {
    top: 0.2em;
  }
}

.results-container,
.facets-container,
.trending-list-container {
  margin-top: 3em;
}

.results-container.col {
  max-width: 70%;
}

.collections-container {
  margin-top: 1.5em;
  text-align: center;
  .result-header {
    h2.bevel {
      margin-bottom: 2em;
      font-size: 1.5rem !important;
      &:before {
        top: 0.14em !important;
      }
    }
  }
}

.facets-container {
  h2.bevel {
    margin-bottom: 2em;
    font-size: 1.5rem !important;
    font-family: 'Source Sans Pro', sans-serif;
    text-transform: uppercase;
    &:before {
      top: 0.14em !important;
    }
  }

  @supports (font-variation-settings: normal) {
    h2.bevel {
      font-family: 'Source Sans Variable', sans-serif;
    }
  }
}

.terms-container {
  .badge {
    a {
      color: white;
      margin-left: 0.2rem;
    }
  }

  .limit,
  .facet {
    margin-right: 0.5rem;
    margin-top: 0.5rem;

    .badge {
      &:not(:last-child) {
        margin-right: 0.3rem;
      }
    }

    .pill-container {
      &:not(:last-child):after {
        font-variant: small-caps;
        font-size: small;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
      }
    }
  }

  .limit {
    .badge {
      background-color: colour(green);
    }

    .pill-container {
      &:not(:last-child):after {
        content: 'or';
        color: colour(green);
      }
    }
  }

  .facet {
    .pill-container {
      &:not(:last-child):after {
        content: 'and';
        color: colour(blue);
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'
import scroll from 'vue-scrollto'
import service from '@/service/service'
import currentRoute from '@/mixins/currentRoute'
import navigation from '@/mixins/navigation'
import search from '@/mixins/search'
import utils from '@/mixins/utils'
import mappings from '@/mixins/mappings'
import SearchBar from '@/components/search/SearchBar.vue'
import CollectionsBar from '@/components/search/CollectionsBar.vue'
import CollectionSearchResult from '@/components/search/CollectionSearchResult.vue'
import Facet from '@/components/search/facets/Facet.vue'
import SearchError from '@/components/search/SearchError.vue'
import TrendingLists from '@/components/lists/TrendingLists.vue'

const FACET_STUB_COUNT = 3
const facetsStub = new Array(FACET_STUB_COUNT).fill(null)
const LIMIT_PREFIX = 'l-'
const SCROLL_DURATION = 1000

const BASIC_FIELD_CONFIGS = {
  'l-site': {
    display: 'Limit by domain',
  },
  'l-usertag': {
    display: 'Tagged',
  },
  'l-publictag': {
    display: 'Tagged',
  },
  subject: {
    display: 'Subject',
  },
  occupation: {
    display: 'Occupation',
  },
}

export default {
  name: 'Search',
  mixins: [search, utils, mappings, currentRoute, navigation],
  components: {
    SearchBar,
    CollectionsBar,
    CollectionSearchResult,
    Facet,
    SearchError,
    TrendingLists,
  },
  props: {
    showCollections: {
      type: Boolean,
      required: false,
      default: true,
    },
    collectionsOverride: {
      type: Array,
      required: false,
    },
    advanced: {
      type: Boolean,
      required: false,
      default: false,
    },
    canAdvanced: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      collections: [],
      results: [],
      previousSearch: {},
      collectionsToSearch: [],
      facets: facetsStub,
      latestSearchResult: null,
      scrolling: false,
      globalSearchError: undefined,
      previousFacets: [],
      displayFieldConfig: BASIC_FIELD_CONFIGS,
    }
  },
  created() {
    const self = this

    self.$emit('update-breadcrumbs', [{ value: 'Search results' }])

    if (this.collectionsOverride) {
      this.collections = this.collectionsOverride
    } else {
      service.landing.getCategories().then((landingConfig) => {
        self.collections = landingConfig
        self.doSearch()
      })
    }

    this.addQueryWatcher(null, () => {
      this.scrollTo()
    })
  },
  computed: {
    displayClearAll() {
      return (this.selectedFacetTerms() || []).length + (this.displayLimits || []).length > 1
    },
    limits() {
      return this.currentFields
    },
    currentFields() {
      const resolveDisplayName = (fieldName, fieldConfig) => {
        return fieldConfig ? fieldConfig.display : null
      }

      const resolveFieldConfig = (key) => {
        return this.displayFieldConfig[key]
          ? this.displayFieldConfig[key]
          : this.displayFieldConfig[(key || '').replace('l-', '')]
      }

      const hasFacets = (facets) => {
        return facets && facets.length > 0 && facets.every((facet) => facet !== null)
      }

      const isFacet = (facets, fieldName) => {
        const fieldNameLookup = fieldName.startsWith('l-') ? fieldName.replace('l-', '') : fieldName
        return facets.some((facet) => facet.code === fieldNameLookup)
      }

      const fields = _.pickBy(this.currentQuery, (value, key) => {
        return key === 'title' || !hasFacets(this.facets) || !isFacet(this.facets, key)
      })

      const currentFields = Object.entries(fields).map(([key, value]) => {
        const fieldConfig = resolveFieldConfig(key)

        return {
          limit: key,
          value: value,
          displayName: resolveDisplayName(key, fieldConfig),
          pillHints: (fieldConfig || {}).pillHints,
        }
      })

      return currentFields
    },
    displayLimits() {
      var self = this

      return _(this.limits)
        .map((limit) => {
          return _.assign({}, limit, {
            value: _.isArray(limit.value) ? limit.value : [limit.value],
          })
        })
        .filter((limit) => {
          return !(limit.limit || '').endsWith('.mod')
        })
        .map((limit) => {
          limit.value = _.map(limit.value, (val) => {
            return { display: val, original: val }
          })
          if (limit.pillHints) {
            if (limit.pillHints.uppercase) {
              limit.value = _.map(limit.value, (val) => {
                return _.isString(val.display)
                  ? {
                      display: val.display.toUpperCase(),
                      original: val.original,
                    }
                  : val
              })
            }
            if (_.isFunction(limit.pillHints.valueMap)) {
              limit.value = _.map(limit.value, (val) => {
                return {
                  display: limit.pillHints.valueMap(val.display),
                  original: val.original,
                }
              })
            }
            if (limit.pillHints.displayOverride) {
              limit.displayName = limit.pillHints.displayOverride
            }
          }
          return limit
        })
        .filter((limit) => {
          if (limit.pillHints) {
            return !(_.isFunction(limit.pillHints.hide)
              ? limit.pillHints.hide()
              : limit.pillHints.hide)
          } else {
            return true
          }
        })
        .filter((limit) => self.advanced || BASIC_FIELD_CONFIGS[limit.limit])
        .filter((limit) => !self.isNonLimitQueryParam(limit.limit))
        .value()
    },
    facetsWithSelectedTerms() {
      return _.filter(this.facets, (facet) => facet && !_.isEmpty(this.selectedFacetTerms(facet)))
    },
    isCollectionSpecified() {
      return this.urlNamespace && this.urlKey
    },
    selectedCollection() {
      var self = this
      return _(self.collections).find(
        (collection) =>
          collection.id &&
          collection.urlNamespace === self.urlNamespace &&
          collection.urlKey === self.urlKey
      )
    },
    selectedCollectionId() {
      return this.selectedCollection ? this.selectedCollection.id : ''
    },
    isCollectionSearch() {
      return this.collectionsToSearch && this.collectionsToSearch.length === 1
    },
    showFacets() {
      return (
        this.isCollectionSearch &&
        !_.isEmpty(this.facets) &&
        (!this.latestSearchResult || this.latestSearchResult.numRecords > 0)
      )
    },
    showTrendingLists() {
      return this.isCollectionSearch && this.collectionsToSearch[0].searchHandler === 'LIST'
    },
    isSummary() {
      return !this.isCollectionSearch
    },
  },
  watch: {
    facets(newVal, oldVal) {
      if (oldVal !== facetsStub) {
        this.previousFacets = oldVal
      }
    },
    currentQuery(newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.resetFacets()
        this.doSearch()
      }
    },
    collectionsOverride(newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.collections = this.collectionsOverride
        this.resetFacets()
        this.doSearch()
      }
    },
    urlNamespace() {
      this.collectionChange()
    },
    urlKey() {
      this.collectionChange()
    },
  },
  methods: {
    fieldConfigUpdated(fieldConfig) {
      this.displayFieldConfig = _.assign(
        {},
        BASIC_FIELD_CONFIGS,
        _.reduce(
          fieldConfig || [],
          (result, fdc) => {
            if (fdc.code) {
              // facet
              result[fdc.code] = {
                name: fdc.code,
                display: fdc.displayName,
                type: 'facet',
                terms: fdc.terms,
              }
            } else if (fdc.display) {
              ;(fdc.subFields || []).forEach((sf) => {
                const fieldDisplayConfig = {
                  name: sf.name,
                  display: sf.display ? `${fdc.display} ${sf.display.toLowerCase()}` : fdc.display,
                  type: sf.type,
                  pillHints: sf.pillHints,
                }

                if (sf.type === 'select') {
                  fieldDisplayConfig.options = sf.options
                }

                result[sf.name] = fieldDisplayConfig
              })
            }

            return result
          },
          {}
        )
      )
    },
    collectionChange() {
      this.facets = []

      if (!_(this.currentQuery).isEmpty()) {
        this.resetFacets()
        this.scrollTo()
        this.doSearch()
      } else {
        this.scrollTo()
      }
    },
    removeFacetsCausingEmptyResultFromQuery(searchCollection, originalQuery, resultFacets) {
      var self = this

      let stripQueryOfSelectedFacets = (originalQuery, selectedFacets) => {
        let strippedQuery = _.cloneDeep(originalQuery)

        _.forEach(selectedFacets, (facet) => {
          delete strippedQuery['l-' + facet.code]
        })

        return strippedQuery
      }

      let flattenTerms = (terms) => {
        return _.reduce(
          terms,
          (allTerms, term) => {
            return _.concat(allTerms, term, flattenTerms(term.subTerms || []))
          },
          []
        )
      }

      // we need to remove all the facets causing this search to be empty
      let query = _.cloneDeep(originalQuery)
      let strippedQuery = stripQueryOfSelectedFacets(query, resultFacets)

      return service.search
        .queryCollection(
          searchCollection.id,
          self.queryToString(strippedQuery),
          self.getLimitsFromQuery(strippedQuery),
          strippedQuery.startPos,
          20,
          strippedQuery.pageTotals,
          strippedQuery.sortBy,
          strippedQuery.explain
        )
        .then((response) => {
          let currentFacets = resultFacets || []
          let keywordSearchFacets = response.facets || []

          _.forEach(currentFacets, (currentFacet) => {
            let keywordSearchMatchingFacet = _.find(
              keywordSearchFacets,
              (facet) => facet.code === currentFacet.code
            )

            if (keywordSearchMatchingFacet) {
              _.forEach(flattenTerms(currentFacet.terms) || [], (currentTerm) => {
                let keywordSearchMatchingTerm = _.find(
                  flattenTerms(keywordSearchMatchingFacet.terms),
                  (term) => term.term === currentTerm.term
                )

                if (!keywordSearchMatchingTerm) {
                  // no matching term, remove from current

                  let queryFacet = query['l-' + currentFacet.code]

                  if (_.isString(queryFacet)) {
                    delete query['l-' + currentFacet.code]
                  } else if (_.isArray(queryFacet)) {
                    _.remove(queryFacet, (term) => term === currentTerm.term)

                    if (queryFacet.length === 1) {
                      query['l-' + currentFacet.code] = queryFacet[0]
                    }
                  }
                }
              })
            } else {
              // no matching facet in keyword serch, remove from current
              delete query['l-' + currentFacet.code]
            }
          })

          return query
        })
    },
    updateLatestSearchResult(result) {
      let self = this

      if (this.selectedCollection && _.isEmpty(result.works) && !_.isEmpty(result.facets)) {
        this.removeFacetsCausingEmptyResultFromQuery(
          this.selectedCollection,
          this.currentQuery,
          result.facets
        ).then((fixedQuery) => {
          if (!_.isEqual(this.currentQuery, fixedQuery)) {
            self.updateSearchParams(self.urlNamespace, self.urlKey, fixedQuery, self.advanced, true)
          } else {
            this.latestSearchResult = result
          }
        })
      } else {
        this.latestSearchResult = result
      }
    },
    searchTypeChanged(advanced) {
      this.updateSearchParams(this.urlNamespace, this.urlKey, this.getKeywordOnlyQuery(), advanced)
    },
    selectedFacetTerms(facet) {
      var self = this

      if (!facet) {
        return _(self.facets)
          .filter((facet) => facet)
          .reduce(
            (selectedTerms, facet) => selectedTerms.concat(self.selectedFacetTerms(facet)),
            []
          )
      }

      var recurseTerms = function (terms) {
        return !terms
          ? []
          : _.reduce(
              terms,
              (allTerms, term) => allTerms.concat([term], recurseTerms(term.subTerms)),
              []
            )
      }

      var selectedFacetTerms = _(recurseTerms(facet.terms))
        .filter((term) => term.selected)
        .map((term) => _.merge(term, { facetCode: facet.code }))
        .value()

      return selectedFacetTerms
    },
    resetFacets() {
      this.facets = facetsStub
    },
    selectedCollectionUpdated(selectedCollection) {
      var collection = selectedCollection || {}
      if (this.urlNamespace !== collection.urlNamespace || this.urlKey !== collection.urlKey) {
        this.resetFacets()
        this.updateSearchParams(
          collection.urlNamespace,
          collection.urlKey,
          this.getKeywordOnlyQuery(true),
          this.advanced
        )
      }
    },
    orderChanged(order) {
      var newQuery = Object.assign({}, this.currentQuery, {
        sortBy: order,
        startPos: 0,
      })

      if (!order) {
        delete newQuery.sortBy
      }

      this.updateSearchParams(this.urlNamespace, this.urlKey, newQuery, this.advanced)
    },
    explainChanged(explain) {
      var newQuery = Object.assign({}, this.currentQuery, { explain: explain })

      if (!explain) {
        delete newQuery.explain
      }

      this.updateSearchParams(this.urlNamespace, this.urlKey, newQuery, this.advanced)
    },
    updateFacets(facets) {
      this.facets = facets
      this.checkForRemovedFacets()
    },
    checkForRemovedFacets() {
      var newQuery = _.cloneDeep(this.currentQuery)
      var removedFacets = _.differenceWith(
        this.previousFacets,
        this.facets,
        (one, two) => one.code === two.code
      )

      _.forEach(removedFacets, (facet) => {
        delete newQuery[LIMIT_PREFIX + facet.code]
      })

      if (!_.isEqual(this.currentQuery, newQuery)) {
        this.updateSearchParams(this.urlNamespace, this.urlKey, newQuery, this.advanced)
      }
    },
    updateSearchParams(urlNamespace, urlKey, query, advanced, replace) {
      this.scrollTo()
      const trimmedQuery = _(query)
        .omitBy((value) => _.isArray(value) && _.isEmpty(value))
        .value()
      this.$emit('search-params-updated', urlNamespace, urlKey, trimmedQuery, advanced, replace)
    },
    search(query) {
      this.globalSearchError = undefined
      if (query && !this.isEmptyQuery(query)) {
        this.updateSearchParams(this.urlNamespace, this.urlKey, query, this.advanced)
      }
    },
    isDuplicateSearch() {
      var currentSearch = {
        urlNamespace: this.urlNamespace,
        urlKey: this.urlKey,
        query: this.currentQuery,
      }

      var isDuplicate = _.isEqual(this.previousSearch, currentSearch)
      this.previousSearch = currentSearch

      return isDuplicate
    },
    doSearch() {
      const self = this

      if (this.isDuplicateSearch()) {
        return
      }

      if (_(self.currentQuery).isEmpty()) {
        return
      }
      this.globalSearchError = undefined

      if (!self.isCollectionSpecified && self.currentQuery.startPos) {
        var newQuery = _.cloneDeep(self.currentQuery)
        delete newQuery.startPos

        self.updateSearchParams(self.urlNamespace, self.urlKey, newQuery, this.advanced)
      }

      var searchCollectionPromise = (function () {
        if (self.selectedCollection) {
          return Promise.resolve(self.selectedCollection)
        }

        if (self.urlNamespace && self.urlKey) {
          service.collection.getConfiguration(self.urlNamespace, self.urlKey)
        }

        return Promise.resolve(null)
      })()
      searchCollectionPromise.then((searchCollection) => {
        self.collectionsToSearch = searchCollection ? [searchCollection] : self.collections
      })
    },
    scrollTo() {
      var self = this
      if (!this.scrolling) {
        this.scrolling = true
        scroll.scrollTo('body', SCROLL_DURATION, {
          force: true,
          cancelable: false,
          onDone: () => (self.scrolling = false),
        })
      }
    },
    globalSearchErrorOccurred(globalError) {
      this.globalSearchError = globalError
    },
  },
}
</script>
