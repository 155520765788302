<template>
  <div>
    <div v-if="loading">Loading...</div>
    <div v-else>
      <h1 v-if="showError">Error</h1>
      <p v-if="showError">The requested resource could not be found.</p>
    </div>
  </div>
</template>

<script>
import service from '@/service/service'

export default {
  name: 'VersionView',
  data() {
    return {
      showError: false,
      loading: true,
    };
  },
  mounted() {
    let versionId = this.$router.history.current.params.versionId
    if (versionId) {
      service.work.workIdFromVersionId(versionId).then((response) => {
        if (response == null) {
          this.showError = true;
          this.loading = false;
          this.$router.push(this.$router.go(-1))
        } else {
          this.$router.replace({
            name: 'Work',
            params: { id: response, versionId: versionId },
          })
        }
      }).catch(() => {
        this.showError = true;
        this.loading = false;
      });

      return
    }

    let nbdId = this.$router.history.current.params.nbdId
    if (nbdId) {
      service.work.workIdFromNbdId(nbdId).then((response) => {
        if (response == null) {
          this.showError = true;
          this.loading = false;
          this.$router.push(this.$router.go(-1))
        } else {
          this.$router.replace({
            name: 'Work',
            params: { id: response.workid, versionId: response.versionid },
          })
        }
      }).catch(() => {
        this.showError = true;
        this.loading = false;
      });

      return
    }

    // If neither versionId nor nbdId is provided, show an error
    this.showError = true;
    this.loading = false;
  },
}
</script>
